import React, { useEffect, useState } from 'react';

// mui
import { Box, Button, Card, CardActions, CardContent, FormControl, IconButton, Input, InputAdornment, InputLabel, MenuItem, Paper, Select, TextField, Tooltip, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FileCopy from '@material-ui/icons/FileCopy';
import DoneIcon from '@material-ui/icons/Done';

// dtos
import { DatePicker } from '@material-ui/pickers';
import { useStoreActions, useStoreState } from '../../hooks';
import { Language, Country, resolveLanguage, resolveCountry } from '../../model/codelist';
import { Brand, LicenseBriefDto, SoftwareDto, LicenseModule, CodeDto, VgnBoardDto } from '../../service/dataContract';
import { DateFormat, formatDate } from '../../utils/datetime';

import CountryInput from '../CountryInput';
import MultipleLanguageInput from '../MultipleLanguageInput';
import MultipleCountryInput from '../MultipleCountryInput';
import MultipleVgnBoardInput from '../MultipleVgnBoardInput';

import LicenseModuleTransferList from './LicenseModuleTransferList';

// styles
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardContent: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            background: theme.palette.background.default,
        },
        cardActions: {
            display: "flex",
            justifyContent: "flex-end",
            background: theme.palette.background.default,
        },
        formControl: {
            margin: theme.spacing(1),
            width: '100%',
            minWidth: '360',
        },
        formControlShort: {
            margin: theme.spacing(1),
            width: '25%',
            minWidth: '50',
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            overflow: 'auto',
        },
    }),
);

interface Props {
    data: LicenseBriefDto
}

interface State extends LicenseBriefDto {
    licenseFile?: File;
    changed: boolean,
}

export const KzvLicenseCard: React.FC<Props> = (props) => {

    const classes = useStyles();

    const { isSuperAdmin, isKzv, isExtern } = useStoreState(state => state.auth);
    const { updateLicense } = useStoreActions(state => state.license);
    const { getSoftware } = useStoreActions(state => state.software);
    const { notify } = useStoreActions(state => state.audit);

    const [software, setSoftware] = useState<SoftwareDto | undefined>(undefined);
    const [allowedLanguages, setAllowedLanguages] = useState<Language[]>([]);
    const [allowedRegions, setAllowedRegions] = useState<Country[]>([]);
    const [allowedVgns, setAllowedVgns] = useState<VgnBoardDto[]>([]);
    const [state, setState] = useState<State>({
        ...props.data,
        changed: false,
    });


    const isKzvLicense5AndAbove = () => state.version >= '5.0';
    const allowVgnsConfig = () => isKzvLicense5AndAbove() && software?.allowVgnsConfig;
    const allowRegionsConfig = () => isKzvLicense5AndAbove() && software?.allowRegionsConfig;
    const isDisabled = () => isExtern;

    useEffect(() => {
        const fetchSoftware = async () => {
            try {
                const licenseSoftware = await getSoftware({ softwareId: props.data.softwareId ?? '' });
                setSoftware(licenseSoftware);
            } catch (err) {
                notify({ severity: 'error', message: 'Error fetching software details' })
            }
        };

        fetchSoftware();

    }, [props.data.id]);

    useEffect(() => {
        setState({ ...props.data, changed: false });
        setAllowedLanguages(props.data.allowedLanguages.map(dto => resolveLanguage(dto.code)));
        setAllowedRegions(props.data.allowedRegions.map(dto => resolveCountry(dto.code)));
        setAllowedVgns(props.data.allowedVgnBoards);
    }, [props.data]);

    const handleStateChange = (prop: keyof State) => (event: React.ChangeEvent<{ value: HTMLInputElement | unknown }>) => {
        setState({ ...state, [prop]: event.target.value, changed: true })
    };

    const handleCountryChange = (country: Country | null) => {
        setState({
            ...state,
            ownerCountryCode: country?.code ?? '',
            ownerCountry: country?.name ?? '',
            changed: true
        })
    };

    const handleLicenseModuleChange = (selection: LicenseModule[]) => {
        setState({ ...state, moduleValues: selection, changed: true });
    };

    const handleExpirationDateChange = (date: Date | null) => setState({ ...state, expiresAt: date, changed: true });

    const handleAllowedLanguagesChange = (selection: Language[]) => {
        setState({ ...state, allowedLanguages: selection.map<CodeDto>(lang => { return { ...lang } }), changed: true });
        setAllowedLanguages(selection);
    }

    const handleAllowedRegionsChange = (selection: Country[]) => {
        setState({ ...state, allowedRegions: selection.map<CodeDto>(region => { return { ...region } }), changed: true });
        setAllowedRegions(selection);
    }

    const handleAllowedVgnsChange = (selection: VgnBoardDto[]) => {
        setState({ ...state, allowedVgnBoards: selection, changed: true });
        setAllowedVgns(selection);
    }

    const handleUpdateLicense = () => {
        updateLicense({
            licenseId: props.data.id,
            license: { ...state }
        });

        // set current date as signature date without re-loading 
        setState({ ...state, lastModifiedAt: new Date(), changed: false });
    };

    return (
        <Card>
            <CardContent className={classes.cardContent}>

                <Box display="flex" flexDirection="row" >

                    <FormControl className={classes.formControl}>
                        <TextField
                            label='Owner Name'
                            value={state.ownerName ?? ''}
                            fullWidth
                            onChange={handleStateChange('ownerName')}
                            inputProps={{ readOnly: isDisabled() }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        <CountryInput
                            label='Owner Country'
                            disabled={isDisabled()}
                            isoCode={state.ownerCountryCode}
                            onChange={handleCountryChange}
                        />
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        <InputLabel shrink>Brand</InputLabel>
                        <Select
                            displayEmpty
                            value={state.brand}
                            disabled={state.version < '4.0'}
                            onChange={handleStateChange('brand')}
                            inputProps={{ readOnly: isDisabled() }}
                        >
                            <MenuItem value={Brand.NotSet}>Not set (treated as KZV)</MenuItem>
                            <MenuItem value={Brand.KZV}>KZV</MenuItem>
                            <MenuItem value={Brand.NCCI}>NCCI</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl className={classes.formControlShort}>
                        <InputLabel shrink>Version</InputLabel>
                        <Select
                            displayEmpty
                            readOnly={!isSuperAdmin}
                            value={state.version}
                            onChange={handleStateChange('version')}
                            inputProps={{ readOnly: isDisabled() }}
                        >
                            <MenuItem value={'5.0'}>5.0 Latest</MenuItem>
                            <MenuItem value={'4.0'}>4.0</MenuItem>                            
                            <MenuItem value={'3.0'}>3.0</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Box display="flex" flexDirection="row">

                    <FormControl className={classes.formControl}>
                        <TextField
                            fullWidth
                            label='License Notes (part of the license)'
                            variant='standard'
                            value={state.notes}
                            onChange={handleStateChange('notes')}
                            inputProps={{ readOnly: isDisabled() }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </FormControl>
                </Box>

                <Box display="flex" flexDirection="row">

                    <FormControl className={classes.formControl}>
                        <DatePicker
                            label="License Expiration Date"
                            size='small'
                            clearable={true}
                            emptyLabel='never'
                            readOnly={isDisabled()}
                            format={DateFormat}
                            value={state.expiresAt}
                            onChange={(date) => handleExpirationDateChange(date)}
                            InputLabelProps={{ shrink: true }} />
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        <InputLabel>License Trial Days</InputLabel>
                        <Select
                            value={state.trialDays}
                            onChange={handleStateChange('trialDays')}
                            inputProps={{ readOnly: isDisabled() }}
                        >
                            <MenuItem value="0">Activated</MenuItem>
                            <MenuItem value="10">10 days</MenuItem>
                            <MenuItem value="30">30 days</MenuItem>
                            <MenuItem value="90">90 days</MenuItem>
                            <MenuItem value="120">120 days</MenuItem>
                        </Select>
                    </FormControl>


                    <FormControl className={classes.formControl}>
                        <TextField
                            label="License Signature Date"
                            size='small'
                            value={formatDate(state.lastModifiedAt)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ readOnly: true }} />
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        <InputLabel shrink>Code / Revision</InputLabel>
                        <Input
                            type='text'
                            onClick={() => { }}
                            value={`${props.data.id} (${props.data.revision ?? 1})`}
                            endAdornment={
                                <InputAdornment position='end'>
                                    <Tooltip title="Copy To Clipboard">
                                        <IconButton
                                            onClick={e => {
                                                navigator.clipboard.writeText(`${props.data.id}${props.data.revision ?? 1}`);
                                                notify({ severity: 'info', message: 'License code copied to clipboard.' });
                                            }}                                        >
                                            <FileCopy />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Box>

                <Box display="flex" flexDirection="row">

                    <FormControl className={classes.formControl}>
                        <TextField
                            fullWidth
                            label='Internal Notes (service web only)'
                            variant='standard'
                            value={state.internalDescription}
                            onChange={handleStateChange('internalDescription')}
                            inputProps={{ readOnly: isDisabled() }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </FormControl>

                </Box>

                <FormControl className={classes.formControl}>
                    <LicenseModuleTransferList
                        title={`${software?.name ?? ''} License Modules`}
                        disabled={isDisabled()}
                        sourceState={software?.softwareModules ?? []}
                        currentState={state?.moduleValues ?? []}
                        selectionChanged={handleLicenseModuleChange}
                         />
                </FormControl>

                {isKzvLicense5AndAbove() && (

                    <Box display="flex" flexDirection="row">

                        <FormControl className={classes.formControl}>
                            <MultipleLanguageInput
                                label='Allowed Languages'
                                disabled={isDisabled()}
                                selection={allowedLanguages}
                                onChange={handleAllowedLanguagesChange} />
                        </FormControl>

                        {allowRegionsConfig() && (
                            <FormControl className={classes.formControl}>
                                <MultipleCountryInput
                                    label='Allowed Regions'
                                    disabled={isDisabled()}
                                    selection={allowedRegions}
                                    onChange={handleAllowedRegionsChange} />
                            </FormControl>
                        )}

                        {allowVgnsConfig() && (
                            <FormControl className={classes.formControl}>
                                <MultipleVgnBoardInput
                                    label='Allowed Vgns'
                                    disabled={isDisabled()}
                                    selection={allowedVgns}
                                    onChange={handleAllowedVgnsChange} />
                            </FormControl>
                        )}
                    </Box>
                )}

            </CardContent>

            <CardActions className={classes.cardActions}>
                {isKzv && (
                    <Box display="flex" flexDirection="row">
                        <Button
                            autoFocus
                            color='secondary'
                            startIcon={<DoneIcon />}
                            disabled={!state.changed}
                            onClick={handleUpdateLicense}>
                            Save
                        </Button>
                    </Box>
                )}
            </CardActions>
        </Card >
    );
}

export default KzvLicenseCard;