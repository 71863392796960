import React, { useEffect, useState } from 'react';

// mui
import { TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { languages, Language } from '../model/codelist';

// styles
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
        },
        margin: {
            margin: theme.spacing(1),
        },
    }),
);

export interface Props {
    className?: string,
    label?: string,
    error?: boolean,
    helperText?: string,
    selection: Language[],
    disabled?: boolean;
    onChange?: (selection: Language[]) => void,
}

export const MultipleLanguageInput: React.FC<Props> = (props) => {

    const classes = useStyles();
    const [state, setState] = useState(props.selection);

    useEffect(() => {
        setState(props.selection);
    }, [props.selection]);

    const handleChange = (selection: Language[]) => {
        setState(selection);
        props.onChange?.(selection);
    };

    return (
        <Autocomplete
            id='language-input'
            multiple
            disabled={props.disabled}
            className={props.className}
            options={languages}
            value={state}
            fullWidth
            getOptionLabel={(option) => `${option.code} - ${option.name}`}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label={props.label}
                    helperText={props.helperText}
                    error={props.error}
                    InputLabelProps={{ shrink: true }}
                    variant='standard'
                />}
            onChange={(_, selectedLanguage) => handleChange(selectedLanguage)}
        />
    )
}

export default MultipleLanguageInput;